<template>
  <div>
    <leaflet-panel
      :body="body"
      :image="image"
      :show-buy-btn="showBuyBtn"
      :show-detail-btn="showDetailBtn"
      :title="title"
    />
    <!-- <ImageList :images="images"></ImageList> -->
    <Paragraph
      :imgurl="require(`@/assets/image/contentsvg/map.svg`)"
      :title="`服务内容`"
      :en_title="`Service Content`"
    ></Paragraph>
    <Titlestrlist style="margin-left: 15%" :datalist="datalist1"></Titlestrlist>
    <Paragraph
      :imgurl="require('@/assets/image/contentsvg/db.svg')"
      :title="`产品优势`"
      :en_title="`Product Advantages`"
    ></Paragraph>
    <Titlenewlinelist
      :datalist="datalist"
      style="margin-left: 15%"
    ></Titlenewlinelist>
    <Paragraph
      :imgurl="require(`@/assets/image/contentsvg/note.svg`)"
      :title="`应用场景`"
      :en_title="`Application Scenarios`"
    >
    </Paragraph>

    <div
      style="
        display: flex;
        flex-direction: row;
        margin-left: 10%;
        margin-right: 10%;
      "
    >
      <Lettertitleblock
        :datalist="datalistx"
        style="
          display: flex;
          flex-direction: row;
          justify-content: center;
          width: 100%;
        "
      >
      </Lettertitleblock>
    </div>
  </div>
</template>

<script>
import LeafletPanel from '@/components/common/LeafletPanel';
// import ImageList from '@/components/common/ImageList';
import Paragraph from '@/components/chain/basic/common/paragraph';
import Titlenewlinelist from '@/components/chain/basic/common/titlenewlinelist';
import Lettertitleblock from '@/components/chain/basic/common/lettertitleblock';
// import Contentlist from '@/components/chain/basic/common/contentlist';
import Titlestrlist from '@/components/chain/basic/common/titlestrlist';

export default {
  name: 'BaaSLeaflet',
  components: {
    LeafletPanel,
    // ImageList,
    Paragraph,
    Titlenewlinelist,
    Lettertitleblock,
    // Contentlist,
    Titlestrlist,
  },
  data() {
    return {
      title: '数字身份服务',
      body:
        '用分布式基础设施改变应用厂商控制数字身份的模式，让用户控制和管理数字身份，'
        + '通过将数据所有权归还用户从根本上解决隐私问题。通过CA认证，为机构、个人颁发链上数字身份证书，完成身份认证。',
      // TODO: REPLACE WITH PRIVACY PANEL IMAGE
      image: require('@/assets/image/chain/va/did/did.svg'),
      showDetailBtn: true,
      showBuyBtn: true,
      images: [
        require('@/assets/image/chain/va/did/8_01.jpg'),
        require('@/assets/image/chain/va/did/8_02.jpg'),
        require('@/assets/image/chain/va/did/8_03.jpg'),
      ],
      datalist: [
        {
          title: '安全性',
          content:
            '身份所有者身份信息不被无意泄露，身份可以由身份持有者持久保存， 身份信息提供可符合最小披露原则',
        },
        {
          title: '身份自主可控',
          content:
            '用户可以自主管理身份，而非依赖可信第三方；身份所有者可 以控制其身份数据的分享',
        },
        {
          title: '身份的可移植性',
          content:
            '身份所有者能够在任何他们需要的地方使用其身份数据，而 不需依赖特定的身份服务提供商',
        },
        {
          title: '真实可信',
          content:
            '确保链上、链下实体身份一一映射，保护隐私的同时让用户相关权益具备法律效益',
        },
      ],
      datalistx: [
        {
          title: '分布式数字身份 + 教育身份',
          imgsrc: 'A',
          list: [
            '可信教育数字身份面向在校',
            '学生、教师、毕业生签发的、具有法律效力的',
            '可信数字身份标识',
            '具有法律效力',
            '打造教育信息可信制度凭证',
          ],
        },
        {
          title: '分布式数字身份 + 房管局网签系统',
          imgsrc: 'B',
          list: [
            '在房产网签中',
            '通过采集用户身份信息',
            '在链上为用户创建DID账户',
            'CA机构对用户信息进行验证',
            '验证通过后在链上颁发可验证数字身份凭证',
            '房管局验证用户的数字证书身份',
            '生成房产交易网签电子合同',
            '用户通过电子签字确认交易',
            '房管局对审核无误后',
            '对电子合同进行电子签章',
            '完成交易',
          ],
        },
      ],
      datalist1: [
        {
          title:
            '> 域乎分布式数字身份，是一套分布式多中心的实体身份标识及可信数据交换解决方案，实现了一套符合W3C DID规范的分布式多中心的身份标识协议和符合W3C VC规范的可验证数字凭证技术。',
          content: '',
        },
        {
          title: '1) ',
          content: '分布式身份标识(DID)',
        },
        {
          title: '2) ',
          content: '可验证数字凭证(Credential)',
        },
        {
          title: '3) ',
          content: '可信身份服务',
        },
        {
          title: '4) ',
          content: '电子签章',
        },
      ],
    };
  },
};
</script>

<style scoped></style>
